<template>
  <section class="configure-mve">
    <el-row class="vendor-mve">
      <el-form ref="configForm"
        :model="configForm"
        :rules="configRules">
        <!-- Left Column: Vendor List -->
        <el-col :span="12"
          class="vendor-content list">
          <h4>{{ $t('general.type-configuration', { product: $t('productNames.mve') }) }}</h4>
          
          <!-- Vendor Selection -->
          <el-form-item :label="$t('ports.mve-select-vendor')"
            prop="selectedVendorProductCode"
            required>
            <mu-item-selector :items="vendorImages"
              id-attribute="productCode"
              class="vendor-select-holder"
              :selected-id="configForm.selectedVendorProductCode"
              :sort-string-function="sortVendorImages"
              emit-selected-item-data
              @select="handleVendorImageSelect">
              <template #cell="{data}">
                <div class="image-cell">
                  <img :src="`https://media.megaport.com/mve-vendors/${kebabVendorName(data.vendor)}.png`"
                    :alt="$t('images.datacentre-logo')"
                    class="datacentre-logo">
                  <div>
                    <p class="vendor-product">
                      {{ `${data.vendor} ${data.product}` }}
                    </p>
                    <new-badge v-if="vendorHasNoReleaseImageVersion(data)"
                      locale-key="general.pre-release"
                      class="small-badge" />
                  </div>
                </div>
              </template>
            </mu-item-selector>
          </el-form-item>
        </el-col>

        <!-- Right Column: Vendor Form -->
        <el-col :span="12"
          class="vendor-content details">
          <!-- Selected Vendor Information -->
          <h4 v-if="selectedVendorImage.product">
            {{ $t('ports.mve-service-details', { vendor: `${selectedVendorImage.vendor} ${selectedVendorImage.product}` }) }}
          </h4>

          <!-- Selected Vendor Version -->
          <div v-if="selectedVendorImage && currentData.vendorConfig._version"
            class="d-flex flex-align-center gap-0-5 vendor-version-text">
            <p>{{ $t('ports.mve-version', { version: currentData.vendorConfig._version }) }}</p>
            <new-badge v-if="!selectedVendorVersion.releaseImage && selectedVendorHasReleasedVersion"
              locale-key="general.pre-release" />
          </div>

          <el-divider v-if="selectedVendorImage.product" />

          <!-- Version Selection -->
          <el-form-item v-if="selectedVendorImage.product"
            :label="$t('general.version')"
            label-width="400px"
            prop="selectedVendorVersion"
            class="mb-0"
            required>
            <el-select v-model="configForm.selectedVendorVersion"
              :placeholder="$t('ports.mve-select-version')"
              class="full-width"
              @change="selectVersion">
              <el-option v-for="version in selectedVendorImage.versions"
                :key="version.name"
                :label="version.name"
                :value="version.name">
                <div class="d-flex justify-content-space-between flex-align-center">
                  <span>{{ version.name }}</span>
                  <new-badge v-if="!version.releaseImage && selectedVendorHasReleasedVersion"
                    locale-key="general.pre-release" />
                </div>
              </el-option>
            </el-select>
          </el-form-item>

          <!-- Vendor Description -->
          <p v-if="selectedVendorImage && currentData.vendorConfig._version"
            class="ml-400px">
            {{ selectedVendorVersion.vendorDescription || '' }}
          </p>

          <!-- Resource Tags -->
          <el-form-item class="ml-200px">
            <resource-tags v-if="selectedVendorImage.product"
              :resource-tags="currentData.resourceTags"
              label-width="200px"
              @update="handleResourceTagChange({ value: $event })" />
          </el-form-item>
          
          <!-- The rest of the fields -->
          <mve-vendors-form v-if="configForm.selectedVendorProductCode && fields.length"
            :key="`vendor-image-${configForm.selectedVendorProductCode}`"
            ref="vendorInputForm"
            :fields="fields"
            :current-data="currentData"
            @update="emitUpdate"
            @isValid="handleValidation" />
        </el-col>
      </el-form>
    </el-row>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { kebabCase } from 'lodash'

import MveVendorsForm from '@/components/mve/MveVendorsForm.vue'
import NewBadge from '@/components/ui-components/NewBadge.vue'
import ResourceTags from '@/components/ui-components/ResourceTags.vue'

export default {
  name: 'MveConfigureMve',

  components: {
    'mve-vendors-form': MveVendorsForm,
    'new-badge': NewBadge,
    'resource-tags': ResourceTags,
  },

  inject: ['disabledFeatures'],

  props: {
    currentData: {
      type: Object,
      required: true,
    },
    imageData: {
      type: Object,
      default: () => null,
    },
    fields: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedVendorImage: { ...this.imageData },
      configForm: {
        selectedVendorProductCode: this.currentData.vendorConfig._productCode,
        selectedVendorVersion: this.currentData.vendorConfig._version,
      },
      configRules: {
        selectedVendorProductCode: {
          required: true, message: this.$t('validations.vendor-image'), trigger: 'blur',
        },
        selectedVendorVersion: {
          required: true, message: this.$t('general.version'), trigger: 'blur',
        },
      },
    }
  },

  computed: {
    ...mapState('MVE', ['images']),
    ...mapGetters('MVE', ['getMVEVendors', 'getImagesForLocationAndVendor']),

    /**
     * Get all images linked to the current selected location and vendor
     * and format accordingly for selecting an image and version number separately
     */
    vendorImages() {
      // Don't bother if we got no images back from the API
      if (!this.images.length) return []

      const imageData = []

      // Get all relevant data for all images for each vendor
      for (const vendor of this.getMVEVendors) {
        const images = this.getImagesForLocationAndVendor(this.currentData.locationId, vendor)
        imageData.push(images)
      }

      // Now combine each vendor product types together along with an array containing each specific version information
      const finalImageData = imageData.flat().reduce((acc, item) => {
        const existingItem = acc.find(i => i.productCode === item.productCode)

        // If the item already exists, add the version to the versions array
        if (existingItem) {
          existingItem.versions.push({
            id: item.id,
            name: item.version,
            sizes: item.sizes,
            details: item.details,
            vendorDescription: item.vendorDescription,
            releaseImage: item.releaseImage,
          })
        } else {
          // If the item doesn't exist, create a new object with the initial version
          acc.push({
            product: item.product,
            vendor: item.vendor,
            productCode: item.productCode,
            versions: [
              {
                id: item.id,
                name: item.version,
                sizes: item.sizes,
                details: item.details,
                vendorDescription: item.vendorDescription,
                releaseImage: item.releaseImage,
              },
            ],
          })
        }

        return acc
      }, [])

      // Sort the versions array by the name in descending order (latest version at the top)
      for (const vendor of finalImageData) {
        vendor.versions.sort((a, b) => b.name.localeCompare(a.name))
      }

      return finalImageData
    },

    selectedVendorVersion() {
      if (!this.selectedVendorImage?.versions?.length) return ''

      return this.selectedVendorImage.versions
        .find(version => version.name === this.configForm.selectedVendorVersion)
    },
    /**
     * Determine if the selected vendor has a released version
     */
    selectedVendorHasReleasedVersion() {
      if (!this.selectedVendorImage?.versions?.length) return false
      return this.selectedVendorImage.versions.some(version => version.releaseImage)
    },
  },

  created() {
    // If editing an in-design MVE, make sure we get the list of versions for the selected image and emit
    if (this.$route.params.productUid && !this.selectedVendorImage?.versions?.length) {
      const selectedProductCode = this.vendorImages
        .find(vendor => vendor.productCode === this.configForm.selectedVendorProductCode)
      this.selectedVendorImage.versions = selectedProductCode.versions
      this.$emit('update:image', this.selectedVendorImage)
    }
  },

  methods: {
    kebabCase,

    /**
     * Sort vendor images by vendor and product name
     * @param {Object} image Vendor image to sort
     */
    sortVendorImages(image) {
      return `${image.vendor} ${image.product}`
    },
    /**
     * Select a vendor image and update the form with the selected image information
     * @param {Object} value Selected vendor image information
     */
    handleVendorImageSelect({ value }) {
      this.selectedVendorImage = value

      const { productCode, vendor, versions } = value

      // Update the selected vendor image using the latest version as default
      // Latest version is always the first in the array
      this.selectedVendorImage.imageId = versions[0].id
      this.configForm.selectedVendorVersion = versions[0].name
      this.configForm.selectedVendorProductCode = productCode
      this.$refs.configForm.clearValidate()

      this.$emit('update:image', this.selectedVendorImage)

      // Update vendor config vendor
      // This is unfortunately still needed for sending back to the pricebook endpoint
      this.emitUpdate({
        value: vendor,
        path: 'vendorConfig._vendor',
      })

      // Update vendor config productCode
      // This is used to determine the selected vendor
      this.emitUpdate({
        value: productCode,
        path: 'vendorConfig._productCode',
      })

      // Update vendor config imageId
      // Latest version is always selected by default
      this.emitUpdate({
        value: versions[0].id,
        path: 'vendorConfig.imageId',
      })

      // Update vendor config version
      // Latest version is always selected by default
      this.emitUpdate({
        value: versions[0].name,
        path: 'vendorConfig._version',
      })
    },
    /**
     * Change the selected version of the vendor image
     * @param {string} value New selected version
     */
    selectVersion(value) {
      const imageId = this.selectedVendorImage.versions.find(version => version.name === value).id

      this.selectedVendorImage.imageId = imageId
      this.configForm.selectedVendorVersion = value

      // Emit the selected vendor image (includes the new imageId for the specific version)
      this.$emit('update:image', this.selectedVendorImage)

      // Update specific vendor config fields
      this.emitUpdate({
        value: imageId,
        path: 'vendorConfig.imageId',
      })
      this.emitUpdate({
        value,
        path: 'vendorConfig._version',
      })
    },
    handleResourceTagChange({ value }) {
      this.emitUpdate({ value, path: 'resourceTags' })
    },
    /**
     * Emit a key value pair for updating the MVE data object
     * @param {string} keyPath THe key path to update
     * @param {string | number | Object} value The new value to set
     */
    emitUpdate({ value, path }) {
      this.$emit('update', { keyPath: path, value })
    },
    handleValidation(isValid) {
      this.$emit('isValid', isValid)
    },
    validate() {
      // handle the select vendor form validation first
      this.$refs.configForm.validate()

      // to handle next button validation
      if (!this.selectedVendorImage) {
        return
      }
      this.$refs.vendorInputForm.validate && this.$refs.vendorInputForm.validate()
    },
    /**
     * Convert vendor name into kebab-case
     * @param {string} vendorName Vendor name
     */
    kebabVendorName(vendorName) {
      return kebabCase(vendorName.toLowerCase())
    },
    /**
     * Determine if every version for a vendor does not have a release image
     * @param {Object} vendor Vendor to check
     */
    vendorHasNoReleaseImageVersion(vendor) {
      if (!vendor?.versions?.length) return false
      return vendor.versions.every(version => !version.releaseImage)
    },
  },
}
</script>

<style lang="scss" scoped>
.configure-mve {
  min-width: 900px;
}

.vendor-mve {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}

.vendor-content {
  padding: 1rem;
  &.list {
    width: 570px;
  }
  &.details {
    width:800px;
  }
}

.image-cell {
  display: flex;
  align-items: center;
  min-height: 5.5rem;
  word-break: normal;
  word-wrap: break-word;

  img {
    margin-right: 3rem;
    margin-left: 1.5rem;
    object-fit: contain;
  }

  .vendor-product {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    flex-grow: 1;
  }

  .vendor-description {
    line-height: 1.25;
  }
}

.ml-400px {
  margin-left: 400px;
  margin-top: 5px;
}

.fine-print {
  font-size: 1.3rem;
  line-height: 1.375em;
  font-weight: 300;
  color: var(--color-text-regular);
  margin: 0;
}

.is-error .vendor-select-holder::v-deep .item-select-holder {
  border-color: var(--color-danger);
}

.small-badge {
  font-size: 10px;
}

.vendor-version-text {
  margin-top: 12px;
  margin-bottom: -6px;
  height: 24px;

  p {
    margin: 0;
  }
}
</style>
