<template>
  <section :data-product-type="mveData.productType">
    <!-- Header with title, steps, next and previous buttons -->
    <header class="process-steps-header"
      :class="{ 'sticky-top': !layout.integratedHeader }">
      <h3 class="text-align-center">
        {{ $t('general.type-details', { type: $t('productNames.mve') }) }}
      </h3>

      <process-steps data-testid="mveDeployedSteps"
        :steps="steps"
        :selected-step.sync="selectedStep"
        :display-complete-as-success="false"
        @validateStep="validateCurrentStep" />
    </header>

    <!-- Content portion of the page -->
    <section class="p-20px flex-column flex-align-center">
      <info-box v-if="!editable"
        :body="nonEditableReason"
        variant="warning" />

      <!-- Information about the MVE -->
      <end-details-card left-icon-id="MVE"
        :detail-lines="detailLines"
        :right-image-src="mveLocation ? `https://media.megaport.com/datacentre/${mveLocation.dc.id}/62x30.png` : ''"
        :diversity-zone="mveData.diversityZone" />

      <price-box :monthly-rate="price.monthlyRate"
        :currency="price.currency" />

      <!-- Details -->
      <megaport-spinner v-if="isLoadingService"
        class="mt-6" />
      <el-card v-else
        shadow="never"
        :class="kebabCase(currentDetailComponent.name)"
        class="content-card">
        <!-- The components that edit and display the content go in here -->
        <component :is="currentDetailComponent"
          ref="currentDetailComponent"
          :current-data="mveDataOnCreated"
          @update="updateData" />

        <!-- Action buttons -->
        <footer class="flex-row-centered justify-content-end mt-2">
          <el-button data-name="close-button"
            @click="handleCloseBtn">
            {{ $t('general.cancel') }}
          </el-button>
          <el-button v-if="selectedStep > 0"
            type="primary"
            data-name="back-button"
            @click="gotoStep(selectedStep - 1)">
            <i class="fas fa-arrow-circle-left"
              aria-hidden="true" /> {{ $t('general.back') }}
          </el-button>
          <el-button v-if="selectedStep < steps.length - 1"
            type="primary"
            :plain="!canNextStep"
            data-name="next-button"
            @click="gotoStep(selectedStep+1)">
            {{ $t('general.next') }}
            <i class="fas fa-arrow-circle-right"
              aria-hidden="true" />
          </el-button>
        </footer>
      </el-card>
    </section>
  </section>
</template>

<script>
// External Tools
import { mapGetters, mapActions } from 'vuex'
import { kebabCase } from 'lodash'
// Internal Tools
import sdk from '@megaport/api-sdk'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import { scopedPriceBook } from '@/utils/priceBook.js'
// Components
import ProcessSteps from '@/components/ui-components/ProcessSteps.vue'
import EndDetailsCard from '@/components/ui-components/EndDetailsCard.vue'
import PriceBox from '@/components/ui-components/PriceBox.vue'
import DeployedConfigure from '@/components/mve/DeployedConfigure.vue'
import DeployedDetails from '@/components/mve/DeployedDetails.vue'
import DeployedLogs from '@/components/mve/DeployedLogs.vue'
import DeployedUsage from '@/components/mve/DeployedUsage.vue'
import InfoBox from '@/components/ui-components/InfoBox.vue'
import MegaportSpinner from '@/components/ui-components/MegaportSpinner.vue'
// Globals
import {
  PROCESS_STEP_UNAVAILABLE,
  PROCESS_STEP_AVAILABLE,
  PROCESS_STEP_COMPLETE,
} from '@/Globals.js'
// route history
import { history } from '@/routeGuards'

export default {
  name: 'MVEDeployed',

  components: {
    'process-steps': ProcessSteps,
    'end-details-card': EndDetailsCard,
    'price-box': PriceBox,
    'info-box': InfoBox,
    'megaport-spinner': MegaportSpinner,
  },

  inject: ['layout', 'disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      // Constants
      dataRefreshInterval: 60 * 1000, // How often we should refresh the data (ms)

      mveDataOnCreated: {},

      selectedStep: 0,
      price: {
        monthlyRate: null,
        currency: null,
      },
    }
  },

  computed: {
    ...mapGetters('Auth', ['hasAuth']),
    ...mapGetters('MVE', ['getMVE']),
    ...mapGetters('Services', ['getLocationById']),
    // MVE-related computed properties
    mveData() {
      return this.getMVE(this.$route.params.productUid)
    },
    mveLocation() {
      return this.getLocationById(this.mveData.locationId)
    },
    detailLines() {
      const detailLines = []
      detailLines.push(this.mveData.productName || this.$t('general.untitled'))
      const vendor = this.mveData.vendor
      const size = this.mveData.mveLabel
      detailLines.push(`${this.$t('ports.vendor')}: ${vendor}, ${this.$t('general.size')}: ${size}`)
      detailLines.push(this.mveLocation.formatted.long)
      return detailLines
    },

    // Step-related computed properties
    steps() {
      const steps = [
        {
          title: this.$t('general.configuration-label'),
          status: PROCESS_STEP_COMPLETE,
          icon: 'el-icon-edit',
          component: DeployedConfigure,
        },
        {
          title: this.$t('general.details'),
          status: PROCESS_STEP_COMPLETE,
          icon: 'el-icon-view',
          component: DeployedDetails,
        },
        {
          title: this.$t('general.logs-label'),
          status: PROCESS_STEP_COMPLETE,
          icon: 'el-icon-view',
          component: DeployedLogs,
        },
        {
          title: this.$t('general.usage-label'),
          status: PROCESS_STEP_COMPLETE,
          icon: 'el-icon-view',
          component: DeployedUsage,
        },
      ]

      if (!this.mveData.productName) {
        steps[0].status = PROCESS_STEP_AVAILABLE
        steps[1].status = PROCESS_STEP_UNAVAILABLE
        steps[2].status = PROCESS_STEP_UNAVAILABLE
        steps[3].status = PROCESS_STEP_UNAVAILABLE
      }
      return steps
    },
    currentDetailComponent() {
      return this.steps[this.selectedStep].component
    },
    canNextStep() {
      return this.steps[this.selectedStep].status === PROCESS_STEP_COMPLETE
    },

    // Permissions-related computed properties
    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    editable() {
      return this.hasAuth('modify_service') && !this.mveData.locked && !this.mveData.adminLocked
    },
    nonEditableReason() {
      if (this.editable) return null

      if (this.mveData.adminLocked) {
        return 'services.service-locked'
      } else if (this.mveData.locked) {
        return 'services.service-admin-locked'
      } else {
        return 'services.edit-permission-denied'
      }
    },
    isLoadingService() {
      return this.mveData.provisioningStatus === this.G_PROVISIONING_LOADING
    },
  },

  watch: {
    'mveData.contractEndDate'(date) {
      if (date === undefined) return

      sdk.instance.resetPriceBookCache()
      if (this.showPricing) this.updatePrice()
    },
  },

  beforeDestroy() {
    this.setPeriodicRefreshEnabled(true)
  },

  mounted() {
    // Don't refresh the data while editing!
    this.setPeriodicRefreshEnabled(false)
  },

  created() {
    // Make sure we have a copy of the data with the resources included. We need this
    // for the price calculation to work.
    if (this.$route.params.productUid) {
      this.fetchService()
      this.mveDataOnCreated = { ...this.getMVE(this.$route.params.productUid) }
    }
  },


  methods: {
    ...mapActions('Services', ['setPeriodicRefreshEnabled']),
    scopedPriceBook,

    validateCurrentStep() {
      // This will update the visual status information for the page
      this.$refs.currentDetailComponent.validate && this.$refs.currentDetailComponent.validate()
    },

    gotoStep(index) {
      const nextButtons = document.querySelectorAll('button[data-name="next-button"]')
      for (const button of nextButtons) {
        button.blur()
      }
      // If the current step is incomplete, then trigger an update of its visual validation
      // status.
      if (this.steps[this.selectedStep].status === PROCESS_STEP_AVAILABLE) {
        this.validateCurrentStep()
      }

      if (this.steps[index].status === PROCESS_STEP_UNAVAILABLE) {
        return false
      }
      this.selectedStep = index
    },

    updateData({ key, value }) {
      this.mveDataOnCreated[key] = value
    },

    fetchService() {
      const params = {
        productUid: this.$route.params.productUid,
        incResources: true,
      }
      this.$store
        .dispatch('Services/fetchServiceOrConnection', params)
        .then(() => {
          if (this.showPricing) this.updatePrice()
        })
        .catch(() => {
          // empty function is intentional
        })
    },

    async updatePrice() {
      if (this.$route.params.productUid && this.mveData?.vendor && this.mveData?.mveSize) {
        try {
          const price = await this.scopedPriceBook()
            .mve(
              this.mveData.locationId,
              this.mveData.vendor,
              this.mveData.mveSize,
              this.mveData.productUid,
              this.mveData.contractTermMonths
            )
          this.price.monthlyRate = price.monthlyRate
          this.price.currency = price.currency
        } catch (error) {
          // Error handled by the pricebook module.
          this.price.monthlyRate = null
          this.price.currency = null
        }
      } else {
        this.price.monthlyRate = null
        this.price.currency = null
      }
    },

    handleCloseBtn() {
      if (history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push(resolveServicesPage())
      }
    },

    kebabCase,
  },
}
</script>

<style lang="scss" scoped>
.content-card {
  min-width: 600px;
}
</style>
